import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { COLORS, productName } from "../../utils/constants";
import { convertRetiralScore, numToValue } from "../../utils/Utils";
import "./pdfPortfolio.scss";

const PdfPortfolio = () => {
  const [pieInfo, setPieInfo] = useState([]);
  const selector = useSelector((state) => state.dashboradReducer);

  let colors = COLORS;

  const showProducts = {
    Gold: {
      link: "https://uat.life99.in/portal/employee/addinvestments/gold",
      typeId: "5fbc74fc-5755-4b34-acbc-ee7943c30bdd",
      current: 2050000,
      effData: "2022-01-27T06:25:49.715Z",
      iconUrl: "https://mvp-be-images.life99.in/DEV/2022/6/Gold.svg",
      retirement: 7677902.175800565,
    },
    Equity: {
      link: "https://uat.life99.in/portal/employee/updateinvestments/equity",
      typeId: "5fbc74fc-5755-4b34-acbc-ee7943c30bdd",
      current: 250000,
      effData: "2022-01-27T06:19:06.817Z",
      iconUrl: "https://mvp-be-images.life99.in/DEV/2022/6/Equity.svg",
      retirement: 2188738.797242224,
    },
    Annuity: {
      link: "https://uat.life99.in/portal/employee/updateinvestments/annuity",
      typeId: "f5e7f7b9-10d4-4972-8d88-0f7cb964e288",
      current: 0,
      effData: "2022-01-28T14:35:27.373Z",
      iconUrl: "https://mvp-be-images.life99.in/DEV/2022/6/Annuity.svg",
      retirement: 150000,
    },
    "Real Estate": {
      link: "https://uat.life99.in/portal/employee/updateinvestments/re",
      typeId: "5fbc74fc-5755-4b34-acbc-ee7943c30bdd",
      current: 1800000,
      effData: "2022-01-27T06:23:11.285Z",
      iconUrl: "https://mvp-be-images.life99.in/DEV/2022/6/Real_Estate.svg",
      retirement: 8971112.319111103,
    },
    "Mutual Funds": {
      link: "https://uat.life99.in/portal/employee/updateinvestments/mf",
      typeId: "5fbc74fc-5755-4b34-acbc-ee7943c30bdd",
      current: 175000,
      effData: "2022-01-27T06:19:46.757Z",
      iconUrl: "https://mvp-be-images.life99.in/DEV/2022/6/Mutual.svg",
      retirement: 1157514.0785321458,
    },
    "Fixed deposit": {
      link: "https://uat.life99.in/portal/employee/updateinvestments/fd",
      typeId: "5fbc74fc-5755-4b34-acbc-ee7943c30bdd",
      current: 500000,
      effData: "2022-01-27T06:22:25.564Z",
      iconUrl: "https://mvp-be-images.life99.in/DEV/2022/6/FDRD.svg",
      retirement: 1872659.0672684305,
    },
    "Public Provident Fund": {
      link: "https://uat.life99.in/portal/employee/updateinvestments/ppf",
      typeId: "f5e7f7b9-10d4-4972-8d88-0f7cb964e288",
      current: 500000,
      effData: "2022-01-27T06:02:54.121Z",
      iconUrl: "https://mvp-be-images.life99.in/DEV/2022/6/PPF.svg",
      retirement: 4377477.594484448,
    },
    "Employee Provident Fund": {
      link: "https://uat.life99.in/portal/employee/updateinvestments/epf",
      typeId: "f5e7f7b9-10d4-4972-8d88-0f7cb964e288",
      current: 2032866.2661222396,
      effData: "2021-12-27T05:53:04.160Z",
      iconUrl: "https://mvp-be-images.life99.in/DEV/2022/6/EPF.svg",
      retirement: 50678142.09466739,
    },
  };
  const retiralInfo = {
    "Gap ": 88830050.51208416,
    "Retial Score": "46.456826547725164",
    "Current Corpus": 7307866.26612224,
    "Required Corpus": 165903596.63919047,
    "Retirement Corpus": 77073546.12710631,
    "Required Corpus one": 150349013.63919047,
    "Required Corpus two": 15554583,
  };

  // putting data in pie chart
  const handlePieData = async () => {
    let tempPie = [];
    Object.entries(showProducts).map(([key, value], index) => {
      let tempInvest = {};
      tempInvest["name"] = `Group ${index}`;
      tempInvest["value"] = value.current;
      tempPie.push(tempInvest);
      return tempPie;
    });
    let pieFiller = handleFillRetiralPie(
      retiralInfo?.["Retial Score"],
      tempPie
    );
    await setPieInfo(pieFiller);
  };

  // filling retiral pie according to retial score and invested product values
  const handleFillRetiralPie = (retiralscore, tempInvest) => {
    if (retiralscore < 1) {
      return [{ name: "Group 0", value: 0 }];
    } else if (retiralscore < 100) {
      let invSum = 0;

      tempInvest?.map((invValue) => {
        invSum += invValue.value;
        return "";
      });
      let remPer = 100 - retiralscore;
      let remValue = (invSum / retiralscore) * remPer;
      let reqAddGroup = [{ name: `Group 999`, value: remValue }];
      let reTempInv = [...tempInvest, ...reqAddGroup];
      return reTempInv;
    } else {
      return tempInvest;
    }
  };

  const pieColor = (pieInfo, index) => {
    return pieInfo[index].name !== "Group 999"
      ? colors[index % colors.length]
      : "#EEE";
  };

  const callFunc = async () => {
    await handlePieData();
  };

  useEffect(() => {
    const callBack = () => {
      callFunc();
    };
    callBack();
    // eslint-disable-next-line
  }, [selector]);
  return (
    <>
      <div className="bg-white pt-3 PdfPortfolio-main " id="retiral-grap">
        <div className=" border-B">
          <div className="row m-auto">
            <div className="col-4 d-flex justify-content-center">
              <div className="position-relative">
                <ResponsiveContainer aspect={1} width={250}>
                  <PieChart>
                    <Pie
                      data={pieInfo}
                      innerRadius={85}
                      outerRadius={105}
                      fill="#8884d8"
                      dataKey="value"
                      isAnimationActive={false}
                    >
                      {pieInfo?.map((value, index) => {
                        return (
                          <Cell
                            key={`cell-${index}`}
                            fill={pieColor(pieInfo, index)}
                            radius={4}
                          />
                        );
                      })}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>

                <div className="retiral-score-abs position-absolute">
                  <span className="RS-head">Retiral Score </span>
                  <p className="retiral-text-small mb-0">
                    {convertRetiralScore(retiralInfo?.["Retial Score"])}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-8 d-flex flex-column justify-content-center">
              <div className="product-grid">
                {Object.entries(showProducts).map(([key, value], index) => {
                  return (
                    <div className="d-flex gap-2 align-items-center">
                      <Box
                        className="cricle"
                        sx={{ backgroundColor: "#005E9E" }}
                      ></Box>
                      <h2 className="product-name">
                        {productName[key]} <span className="font-inter">₹</span>{" "}
                        {numToValue(value.current)}
                      </h2>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PdfPortfolio;
