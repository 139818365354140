import cardill1 from "../assets/images/New Recommended Card ill/Savings.svg";
import cardill2 from "../assets/images/New Recommended Card ill/Investment.svg";
import cardill3 from "../assets/images/New Recommended Card ill/Retirement.svg";
import cardill4 from "../assets/images/New Recommended Card ill/Pension.svg";

import investRec1 from "../assets/images/New Recommended Card ill/Invest-reill-1.png";
import investRec2 from "../assets/images/New Recommended Card ill/Invest-reill-2.png";
import investRec3 from "../assets/images/New Recommended Card ill/Invest-reill-3.png";
import investRec4 from "../assets/images/New Recommended Card ill/Invest-reill-4.png";

import Annuity from "../assets/images/productDashborad/Annuity.svg";
import Equity from "../assets/images/productDashborad/Equity.svg";
import FDRD from "../assets/images/productDashborad/FDRD.svg";
import Gold from "../assets/images/productDashborad/Gold.svg";
import Mutual from "../assets/images/productDashborad/Mutual.svg";
import NPS from "../assets/images/productDashborad/NPS.svg";
import Postofficeschemes from "../assets/images/productDashborad/Postofficeschemes.svg";
import PPF from "../assets/images/productDashborad/PPF.svg";
import SSY from "../assets/images/productDashborad/SSY.svg";
import TermDeposit from "../assets/images/productDashborad/Term_Deposit_Icon.svg";
import EPF from "../assets/images/productDashborad/EPF.svg";
import SAF from "../assets/images/productDashborad/Superannuation.svg";
import RealEstate from "../assets/images/productDashborad/Real_Estate.svg";
import GHI from "../assets/images/productDashborad/Group_Health_Insurance_Icon.svg";
import IHI from "../assets/images/productDashborad/Individual_Health_Insurance_Icon.svg";
import Gratuity from "../assets/images/productDashborad/Gratuity_1.svg";

import cardill5 from "../assets/images/New Recommended Card ill/Retiral Calculator.svg";
import cardill7 from "../assets/images/New Recommended Card ill/Financial Immunity Calculator.png";
import cardill8 from "../assets/images/New Recommended Card ill/Financial Guide booklet.png";

export const apiBaseUrl = process.env.REACT_APP_API_DOMAIN;

export const supportConstants = {
  mailId: "customercare_life99@hdfcpension.com",
  mobile: "08916687777",
};

export const webinarConstants = {
  pastWebinar: "/api-mdm/webinar?webinarStatus=past",
  upcomingWebinar: "/api-mdm/webinar?webinarStatus=upcoming",
};

export const articleConstants = {
  getArticle: "https://api.life99.in/api-cms/knowledge-center/get-articles/",
};

export const serviceCornerConstants = {
  submitCertificate: "https://lifecertificate.hdfclife.com/",
  downloadAnnuity:
    "https://life99.in/portal/employee/investments/summury/annuity",
  downloadSuperAnnuation: "https://life99.in/portal/employee/products/saf",
  migrateAccount: "https://life99.in/portal/employee/npsbuy",
};

export const clickToWellness =
  " https://engagement-portal-uat.apps-hdfclife.com/life-sso";

export const ageLink =
  "https://uat.life99.in/portal/employee/calculators/retirement/age-tymer";

export const healthScoreLink = `${process.env.REACT_APP_LIFE99}/portal/employee/services/health-wellness/score-calculate`;

export const communityExploreNow = "https://www.getsetup.io/partner/life99";

export const COLORS = [
  "#3268a8",
  "#4aaac1",
  "#7b5aa6",
  "#00c0f3",
  "#a8b6c0",
  "#ee6b3b",
  "#34495e",
  "#9b59b6",
  "#2ecc71",
  "#e67e22",
  "#2d3436",
  "#55efc4",
  "#c56cf0",
  "#ff4d4d",
  "#2c2c54",
];

export const RetrialScoreSliderSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const newsCategory = {
  all: "All",
  retirement: "Retirement",
  investment: "Investment",
  taxPlanning: "Tax Planning",
  inheritance: "Inheritance",
  healthWellness: "Health & Wellness",
};

export const wellBeingTabs = {
  All: "All",
  //   "Stay fit": "Stay fit",
  "Tax services": "Tax services",
  "Will planning": "Will planning",
  "Health & Wellness": "Health & Wellness",
  Community: "Community",
};

export const idealInvsetment = [
  { H: 5, debt: 100, MF: 0, Equity: 0 },
  { H: 6, debt: 80, MF: 20, Equity: 0 },
  { H: 7, debt: 70, MF: 20, Equity: 10 },
  { H: 8, debt: 40, MF: 30, Equity: 30 },
  { H: 9, debt: 20, MF: 40, Equity: 40 },
];

export const dashboardRecommended = [
  {
    Header: "Savings",
    subHeader: "Invest in NPS System & get additional tax benefit",
    imageUrl: cardill1,
    backGround: "linear-gradient(340.78deg, #4AAAC1 2.17%, #206F82 114.02%)",
  },
  {
    Header: "Retirement",
    subHeader: "Invest in NPS System & get additional tax benefit",
    imageUrl: cardill2,
    backGround: "linear-gradient(335.55deg, #000000 -47.19%, #C296E4 112.87%)",
  },
  {
    Header: "Pension",
    subHeader: "Invest in NPS System & get additional tax benefit",
    imageUrl: cardill3,
    backGround: "linear-gradient(152.45deg, #1F569D 9.98%, #0C1A2D 133.67%)",
  },
  {
    Header: "Investment",
    subHeader: "Invest in NPS System & get additional tax benefit",
    imageUrl: cardill4,
    backGround: "linear-gradient(338.03deg, #FDA135 1.55%, #1E3F6D 113.05%)",
  },
];

export const investRecommendedData = [
  {
    header: "Age Tymer",
    subHeader: "",
    imageUrl: investRec1,
    backGround: "linear-gradient(340.78deg, #4AAAC1 2.17%, #206F82 114.02%)",
    link: "https://uat.life99.in/portal/employee/calculators/retirement/age-tymer",
  },
  {
    header: "Income Tax Calculator",
    subHeader: "",
    imageUrl: investRec2,
    backGround: "linear-gradient(335.55deg, #000000 -47.19%, #C296E4 112.87%)",
    link: "https://www.hdfclife.com/financial-tools-calculators/income-tax-calculator",
  },
  {
    header: "Digital Human life value",
    subHeader: "",
    imageUrl: investRec3,
    backGround: "linear-gradient(152.45deg, #1F569D 9.98%, #0C1A2D 133.67%",
    link: "https://www.hdfclife.com/campaigns/Digital-HLV/",
  },
  {
    header: "Life Freedom Index",
    subHeader: "",
    imageUrl: investRec4,
    backGround: "linear-gradient(338.03deg, #FDA135 1.55%, #1E3F6D 113.05%)",
    link: "https://www.hdfclife.com/campaigns/hdfclfigame/",
  },
];

export const articleUrl = `${process.env.REACT_APP_API_DOMAIN}/api-cms`;

export const preLoginProduct = [
  {
    product: "NPS",
    comingSoon: "",
    productDesc: "National Pension Scheme",
    image: NPS,
  },
  {
    product: "PPF",
    comingSoon: "",
    productDesc: "Public Provident Fund",
    image: PPF,
  },
  {
    product: "Annuity (Pension)",
    comingSoon: "",
    productDesc: "A fixed sum of money paid each year",
    image: Annuity,
  },
  {
    product: "FD & RD",
    comingSoon: "Coming soon",
    productDesc: "Fixed & Recurring bank deposits",
    image: FDRD,
  },
  {
    product: "Post office schemes",
    comingSoon: "Coming soon",
    productDesc: "Small savings schemes",
    image: Postofficeschemes,
  },

  {
    product: "Sukanya Samriddhi Yojana",
    comingSoon: "Coming soon",
    productDesc: "Saving schemes for girl child",
    image: SSY,
  },
  {
    product: " Mutual Funds",
    comingSoon: "",
    productDesc: "Professionally managed  investment schemes",
    image: Mutual,
  },
  {
    product: "Equity",
    comingSoon: "Coming soon",
    productDesc: "Invest in companies equities",
    image: Equity,
  },
  {
    product: "Gold",
    comingSoon: "Coming soon",
    productDesc: "Invest in gold digitally",
    image: Gold,
  },
];

export const summaryProducts = [
  "NPS",
  "PPF",
  "Annuity",
  "FD & RD",
  "Post office schemes",
  "Mutual Fund",
  "SSY",
  "Equity",
  "Gold",
];

export const emptyNavCalProducts = [
  {
    header: "Retiral Calculator",
    subHeader: "",
    imageUrl: cardill5,
    backGround: "linear-gradient(180deg, #DC5E28 0%, #F5E0C7 100%)",
    link: "",
  },
  // {
  //   header: "NPS Calculator",
  //   subHeader: "",
  //   imageUrl: cardill6,
  //   backGround: "linear-gradient(180deg, #104C83 0%, #C7DFF5 100%)",
  //   link: "https://uat.life99.in/portal/employee/calculators/npscalculator",
  // },
  {
    header: "Financial Immunity Calculator",
    subHeader: "",
    imageUrl: cardill7,
    backGround: "linear-gradient(180deg, #778523 0%, #E5F0A0 100%)",
    link: "https://financial-immunity-calculator.apps-hdfclife.com/",
  },
  {
    header: "Financial Guide to Bounce Back booklet",
    subHeader: "",
    imageUrl: cardill8,
    backGround: "linear-gradient(180deg, #9C7EF1 0%, #EBE3FF 100%)",
    link: "https://brandsite-static.hdfclife.com/media/documents/apps/HDFC_Life_Financial_Guide_to_Bounce_Back.pdf",
  },
];

export const preLoginVideo =
  "https://www.youtube.com/watch?v=bkxw_T3i-Fk&ab_channel=Life99%7CbyHDFCPension";

export const productName = {
  "National Pension System": "NPS",
  Annuity: "Annuity (Pension)",
  "Employee Provident Fund": "EPF",
  "Public Provident Fund": "PPF",
  "Superannuation Fund": "Superannuation Funds",
  Gratuity: "Gratuity",
  "Leave Encashment": "LE",
  Equity: "Equity",
  "Mutual Funds": "Mutual Funds",
  "Insurance - Maturity benefit": "IMB",
  "Fixed deposit": "FD",
  "Real Estate": "Real Estate",
  Gold: "Gold",
  "Group Term Insurance": "GTI",
  "Individual Term Insurance": "INS",
  "Group Health Insurance": "Group Health Insurance",
  "Individual Health Insurance": "Individual Health Insurance",
  "Term Deposit": "Term Deposit",
  "Post office schemes": "Post office schemes",
  "Sukanya Samriddhi Yojana": "Sukanya Samriddhi Yojana",
  "HDFC Life Sanchay Plus": "HDFC Life Sanchay Plus",
  "Click to Protect life": "Click to Protect life",
};

export const productShowDashborad = [
  "National Pension System",
  "Public Provident Fund",
  "Annuity",
  "Mutual Funds",
];

export const legalDisclamerPdfUrl =
  "https://mvp-be-images.life99.in/DEV/2022/4/ldnew.pdf";

export const playStoreLink =
  "https://play.google.com/store/apps/details?id=com.hdfclife.life99";

export const reviewPredeined = [
  {
    name: "amazing",
    reaction: "🤩 So amazing",
    fullReaction:
      "🤩 So amazing ,It was really amazing experience with these pension plans.",
  },
  {
    name: "good",
    reaction: "👍🏼 Good",
    fullReaction:
      "👍🏼 Good ,Really informative way to get to know reitirement plan.",
  },
  {
    name: "Ok",
    reaction: "😅 Ok",
    fullReaction:
      "😅 Ok ,It was really amazing experience with these pension plans.",
  },
  {
    name: "not-good",
    reaction: "😞 Not good",
    fullReaction: "😞 Not good ,plans are good purchasing process wasn't good.",
  },
  {
    name: "terrible",
    reaction: "😡 Terrible",
    fullReaction:
      "😡 Terrible ,none plan matches to my requirement.Need more invetment plan.",
  },
];

export const appLinks = {
  googlePlay: {
    link: "https://play.google.com/store/apps/details?id=com.hdfclife.life99",
    barcode: "",
  },
  appStore: {
    link: "https://play.google.com/store/apps/details?id=com.hdfclife.life99",
    barcode: "",
  },
};

export const goalImages =
  [
    "https://mvp-be-images.life99.in/DEV/2022/5/Dream_home-min.jpg",
    "https://mvp-be-images.life99.in/DEV/2022/5/Child_education2-min.jpg",
    "https://mvp-be-images.life99.in/DEV/2022/5/marriage-min.jpg",
    "https://mvp-be-images.life99.in/DEV/2022/5/Medical_emergency-min.jpg",
    "https://mvp-be-images.life99.in/DEV/2022/5/travel-min.jpg",
    "https://mvp-be-images.life99.in/DEV/2022/5/Customised_goal-min.jpg",
  ] || [];

export const goalIcons =
  [
    "https://mvp-be-images.life99.in/DEV/2022/5/New_Home_Icon.svg",
    "https://mvp-be-images.life99.in/DEV/2022/5/Child_Education_Icon.svg",
    "https://mvp-be-images.life99.in/DEV/2022/5/Child_Marriage_Icon.svg",
    "https://mvp-be-images.life99.in/DEV/2022/5/Medical_Emergency_Icon.svg",
    "https://mvp-be-images.life99.in/DEV/2022/5/Travel_Icon.svg",
    "https://mvp-be-images.life99.in/DEV/2022/5/Personal_Expense_Icon.svg",
  ] || [];

export const productIcon = {
  "National Pension System": NPS,
  "Public Provident Fund": PPF,
  Annuity: Annuity,
  "Mutual Funds": Mutual,
  "Insurance - Maturity benefit":
    "https://mvp-be-images.life99.in/DEV/2022/2/Insurance_maturity_benefits_.svg",
  "Post office schemes": Postofficeschemes,
  "Sukanya Samriddhi Yojana": SSY,
  "Individual Term Insurance":
    "https://mvp-be-images.life99.in/DEV/2022/2/Term-insurance.svg",
  "Term Deposit": TermDeposit,
  "Click to Protect life":
    "https://mvp-be-images.life99.in/DEV/2022/2/ShieldCheck.svg",
  "HDFC Life Sanchay Plus":
    "https://s3.ap-south-1.amazonaws.com  /mvp-dev-be.life99/DEV/2022/2/ShieldPlus.svg",
  Equity: Equity,
  Gold: Gold,
  "Fixed deposit": FDRD,
  "Employee Provident Fund": EPF,
  "Superannuation Fund": SAF,
  "Real Estate": RealEstate,
  "Group Health Insurance": GHI,
  "Individual Health Insurance": IHI,
  Gratuity: Gratuity,
};

export const EXPENSEASPERAGE = {
  28: {
    EMI: "11.76",
    HOUSERENT: "17.64",
    UTILITY: "3.53",
    CHILDRELATED: "0",
    MEDICALHEALTH: "1.88",
    DOMESTIC: "5.88",
    CONVENYANCE: "5.88",
    FOODBEVARAGES: "9.41",
    LEISURE: "9.41",
    TRAVELVACATION: "11.76",
    OTHER: "0.49",
    EXPENSE: 76.92,
    SAVINGS: 23.08,
  },
  32: {
    EMI: "11.76",
    HOUSERENT: "17.64",
    UTILITY: "3.53",
    CHILDRELATED: "0",
    MEDICALHEALTH: "1.18",
    DOMESTIC: "5.88",
    CONVENYANCE: "5.88",
    FOODBEVARAGES: "9.41",
    LEISURE: "9.41",
    TRAVELVACATION: "11.76",
    OTHER: "0.49",
    EXPENSE: 76.92,
    SAVINGS: 23.08,
  },
  35: {
    EMI: "11.76",
    HOUSERENT: "18.21",
    UTILITY: "4.55",
    CHILDRELATED: "4.55",
    MEDICALHEALTH: "4.55",
    DOMESTIC: "7.28",
    CONVENYANCE: "6.37",
    FOODBEVARAGES: "9.10",
    LEISURE: "9.10",
    TRAVELVACATION: "11.38",
    OTHER: "0.59",
    EXPENSE: 87.38,
    SAVINGS: 12.62,
  },
  40: {
    EMI: "31.34",
    HOUSERENT: "0.00",
    UTILITY: "4.87",
    CHILDRELATED: "6.96",
    MEDICALHEALTH: "4.87",
    DOMESTIC: "5.57",
    CONVENYANCE: "4.87",
    FOODBEVARAGES: "9.05",
    LEISURE: "8.36",
    TRAVELVACATION: "10.45",
    OTHER: "0.52",
    EXPENSE: 86.88,
    SAVINGS: 13.12,
  },
  48: {
    EMI: "31.34",
    HOUSERENT: "0.00",
    UTILITY: "4.87",
    CHILDRELATED: "6.96",
    MEDICALHEALTH: "4.87",
    DOMESTIC: "5.57",
    CONVENYANCE: "4.87",
    FOODBEVARAGES: "9.05",
    LEISURE: "8.36",
    TRAVELVACATION: "10.45",
    OTHER: "0.52",
    EXPENSE: 86.88,
    SAVINGS: 13.12,
  },
  50: {
    EMI: "0.00",
    HOUSERENT: "0.00",
    UTILITY: "2.99",
    CHILDRELATED: "33.19",
    MEDICALHEALTH: "8.30",
    DOMESTIC: "6.64",
    CONVENYANCE: "3.98",
    FOODBEVARAGES: "3.98",
    LEISURE: "3.98",
    TRAVELVACATION: "13.83",
    OTHER: "1.38",
    EXPENSE: 78.26,
    SAVINGS: 21.74,
  },
  55: {
    EMI: "0.00",
    HOUSERENT: "0.00",
    UTILITY: "2.30",
    CHILDRELATED: "5.76",
    MEDICALHEALTH: "23.02",
    DOMESTIC: "6.91",
    CONVENYANCE: "3.45",
    FOODBEVARAGES: "2.76",
    LEISURE: "2.30",
    TRAVELVACATION: "9.59",
    OTHER: "1.44",
    EXPENSE: 57.54,
    SAVINGS: 42.46,
  },
  58: {
    EMI: "0.00",
    HOUSERENT: "0.00",
    UTILITY: "1.84",
    CHILDRELATED: "0.00",
    MEDICALHEALTH: "18.43",
    DOMESTIC: "5.53",
    CONVENYANCE: "2.76",
    FOODBEVARAGES: "1.84",
    LEISURE: "1.47",
    TRAVELVACATION: "4.61",
    OTHER: "38.40",
    EXPENSE: 74.9,
    SAVINGS: 25.1,
  },
  65: {
    EMI: "0.00",
    HOUSERENT: "0.00",
    UTILITY: "1.51",
    CHILDRELATED: "0.00",
    MEDICALHEALTH: "15.06",
    DOMESTIC: "4.52",
    CONVENYANCE: "2.26",
    FOODBEVARAGES: "1.51",
    LEISURE: "1.20",
    TRAVELVACATION: "3.77",
    OTHER: "31.38",
    EXPENSE: 61.2,
    SAVINGS: 38.8,
  },
  66: {
    EMI: "0.00",
    HOUSERENT: "0.00",
    UTILITY: "1.51",
    CHILDRELATED: "0.00",
    MEDICALHEALTH: "15.06",
    DOMESTIC: "4.52",
    CONVENYANCE: "2.26",
    FOODBEVARAGES: "1.51",
    LEISURE: "1.20",
    TRAVELVACATION: "3.77",
    OTHER: "31.38",
    EXPENSE: 61.2,
    SAVINGS: 38.8,
  },
};

export const TAXCALCULATION = {
  28: {
    TAXSAVING: 50000,
    EIGHTYCSAVINGS: 150000,
    EIGHTYDSAVINGS: 25000,
    EIGHTYEESAVINGS: 50000,
    SAVINGSTOTAL: 275000,
  },
  32: {
    TAXSAVING: 50000,
    EIGHTYCSAVINGS: 150000,
    EIGHTYDSAVINGS: 25000,
    EIGHTYEESAVINGS: 50000,
    SAVINGSTOTAL: 275000,
  },
  35: {
    TAXSAVING: 50000,
    EIGHTYCSAVINGS: 150000,
    EIGHTYDSAVINGS: 25000,
    EIGHTYEESAVINGS: 200000,
    SAVINGSTOTAL: 425000,
  },
  40: {
    TAXSAVING: 50000,
    EIGHTYCSAVINGS: 150000,
    EIGHTYDSAVINGS: 25000,
    EIGHTYEESAVINGS: 200000,
    SAVINGSTOTAL: 425000,
  },
  48: {
    TAXSAVING: 50000,
    EIGHTYCSAVINGS: 150000,
    EIGHTYDSAVINGS: 25000,
    EIGHTYEESAVINGS: 200000,
    SAVINGSTOTAL: 425000,
  },
  50: {
    TAXSAVING: 50000,
    EIGHTYCSAVINGS: 150000,
    EIGHTYDSAVINGS: 25000,
    EIGHTYEESAVINGS: 1,
    SAVINGSTOTAL: 225000,
  },
  55: {
    TAXSAVING: 50000,
    EIGHTYCSAVINGS: 150000,
    EIGHTYDSAVINGS: 25000,
    EIGHTYEESAVINGS: 1,
    SAVINGSTOTAL: 225000,
  },
  58: {
    TAXSAVING: 50000,
    EIGHTYCSAVINGS: 150000,
    EIGHTYDSAVINGS: 25000,
    EIGHTYEESAVINGS: 1,
    SAVINGSTOTAL: 225000,
  },
  60: {
    TAXSAVING: 50000,
    EIGHTYCSAVINGS: 150000,
    EIGHTYDSAVINGS: 25000,
    EIGHTYEESAVINGS: 1,
    SAVINGSTOTAL: 225000,
  },
};

export const hyperLink = {
  addInstrumnet: `${process.env.REACT_APP_MVP}/portal/employee/linkInvestment`,
  calculator: `${process.env.REACT_APP_MVP}/portal/employee/calculators/retirement`,
  plan: `${process.env.REACT_APP_MVP}/portal/employee/plan`,
  advisor: `${process.env.REACT_APP_MVP}/portal/employee/advisory`,
};
export const productLink = {
  sanchayFmp:
    "https://onlineinsurance.hdfclife.com/buy-online-savings-plans/sanchay-fixed-maturity-plan/basic-details?&source=G_LS_Sanchay_Brand_2056563991-aud-770904918431:kwd-1605924119935&utm_source=Google&utm_medium=SEM&utm_campaign=HDFC_Life_Sanchay_Brand&utm_adgroup=HDFC_FMP&agentcode=00760655&product=Sanchay&utm_term=sanchay%20fmp&utm_network=g&utm_matchtype=p&utm_device=c&utm_placement=&utm_content=601457432105&utm_Adposition=&utm_location=9062230&utm_Sitelink=&gclid=EAIaIQobChMI1dm0qOiY-AIVA5JmAh2v8AHSEAAYASAAEgKj8vD_BwE&gclsrc=aw.ds",
  sanchayPar:
    "https://onlineinsurance.hdfclife.com/buy-online-savings-plans/sanchaypar-savings-plan?source=NW_SANPAR_BuyNow&agentcode=00399206&language=en",
  clickToWealth:
    "https://onlineinsurance.hdfclife.com/buy-online-investment-plans/click-2-wealth-plan/Start-Investing/Lead-Form?ocpseqno=1&source=NW_C2W_BuyNow&agentcode=00399206&language=c2w_EN&categcd=INVT",
};
